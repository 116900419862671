import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PageLayout from "../components/layout/pageLayout";
import Accordion from "../components/accordion";
import Marquee from "react-fast-marquee";
import { NavLink } from "react-router-dom";

const Landing = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const words = ["Students", "Techies", "Devs", "Learners"];
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const difference = +new Date("2024-10-11") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  }

  const imageVariants = {
    animate: {
      y: [0, -10, 0],
      opacity: [1, 0.8, 1],
      scale: [1, 1.02, 1],
      transition: {
        y: {
          repeat: Infinity,
          repeatType: "mirror",
          duration: 5,
        },
        opacity: {
          repeat: Infinity,
          repeatType: "mirror",
          duration: 5,
        },
        scale: {
          repeat: Infinity,
          repeatType: "mirror",
          duration: 5,
        },
      },
    },
  };

  const containerVariants = {
    animate: {
      transition: {
        staggerChildren: 1.5, // Stagger the start of each child animation by 0.5 seconds
      },
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000); // Change word every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <PageLayout>
      <div className="bg-white bg-hero-pattern bg-cover">
        <div className="max-w-[1440px] mx-auto flex flex-col lg:flex-row justify-between py-8 px-4 md:px-8 lg:px-16 text-white gap-4  min-h-[500px]">
          <div className="lg:w-[40%] flex flex-col justify-center py-4 lg:py-0">
            <AnimatePresence>
              <h1 className="w-full text-secondary-color text-[30px] md:text-[40px] max-w-[90%] md:max-w-[500px] lg:max-w-full text-center lg:text-start mx-auto lg:mx-0 lg:text-[50px] font-GilroyBlackItalic">
                Ibadan Fastest Growing GenZ Event for{" "}
                <motion.span
                  // key={words[currentIndex]}
                  initial={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className=" text-primary-color bg-white px-4"
                >
                  {words[currentIndex]}
                </motion.span>{" "}
                in Tech
              </h1>
            </AnimatePresence>
            <div className="flex flex-col sm:flex-row justify-center lg:justify-start gap-4 mt-4 font-GilroyBoldItalic">
              <button className="border-primary-color hover:bg-transparent border-[2px] hover:text-primary-color bg-primary-color text-white mx-auto sm:mx-0 w-[80%] sm:w-[150px] px-4 py-2 rounded-[10px]">
                View Schedule
              </button>
              <a href="#ticket" className="flex">
                <button className="border-primary-color hover:bg-transparent border-[2px] hover:text-primary-color bg-primary-color text-white mx-auto sm:mx-0 w-[80%] sm:w-[150px] px-4 py-2 rounded-[10px]">
                  Get a ticket
                </button>
              </a>
            </div>
            <div className="mt-8 lg:mt-16 mb-4 lg:mb-0 mx-auto lg:mx-0  text-primary-color font-GilroyBoldItalic text-[20px] text-center lg:text-start">
              <p className="mb-2">Date: Friday, 11th - 12th, October 2024</p>
              <p>Venue: Dominion University, Lagos - Ibadan Expressway.</p>
            </div>
            <p className="text-primary-color font-GilroyBoldItalic text-[50px] md:text-[70px] mx-auto lg:mx-0">
              {timeLeft.days}:{timeLeft.hours}:{timeLeft.minutes}:
              {timeLeft.seconds}
            </p>
          </div>
          <motion.div
            className="lg:md:w-[55%] flex justify-center gap-6 pr-4"
            initial="hidden"
            animate="animate"
            variants={containerVariants}
          >
            <div className="flex flex-col gap-4">
              <motion.div variants={imageVariants}>
                <img
                  src="/images/hero1.png"
                  alt=""
                  className="w-[100%] rounded-[10px] grayscale object-cover hover:grayscale-0"
                />
              </motion.div>
              <motion.div variants={imageVariants}>
                <img
                  src="/images/hero3.png"
                  alt=""
                  className="w-[100%] rounded-[10px] grayscale object-cover hover:grayscale-0 ml-3"
                />
              </motion.div>
            </div>
            <div className="flex flex-col gap-4 mt-20">
              <motion.div variants={imageVariants}>
                <img
                  src="/images/hero2.png"
                  alt=""
                  className="w-[100%] rounded-[10px] grayscale object-cover hover:grayscale-0"
                />
              </motion.div>
              <motion.div variants={imageVariants}>
                <img
                  src="/images/hero4.png"
                  alt=""
                  className="w-[100%] rounded-[10px] grayscale object-cover hover:grayscale-0 ml-3"
                />
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="bg-primary-color text-white text-[24px] px-4 md:px-8 lg:px-16 py-4">
        <p className="max-w-[900px] mx-auto text-center font-GilroyBoldItalic">
          "The go-to spot for{" "}
          <span className="text-secondary-color">tech-savvy Gen Z</span> in
          Ibadan to connect, grow and unlock amazing possibilities "
        </p>
      </div>

      <div
        className="py-12 px-4 md:px-8 lg:px-16 font-GilroyBoldItalic w-full max-w-[1200px] mx-auto"
        id="about"
      >
        <h2 className=" text-[24px] mb-4 text-center">
          About the Event - IBstfest 24
        </h2>
        <p className="w-full text-[20px] max-w-[900px] mx-auto text-primary-color text-center">
          "The Ibadan Students Tech Fest is a gathering for students in Ibadan.
          It aims to demystify technology by providing insights into how it
          works and equipping individuals with the skills to navigate the tech
          landscape."
        </p>
        <NavLink
          to="/about"
          className="font-GilroyBold mt-6 block w-full max-w-[300px] md:text-[18px]  text-center border-primary-color hover:bg-transparent border-[2px] hover:text-primary-color bg-primary-color text-white mx-auto  px-4 py-1 rounded-[10px]"
        >
          Read More
        </NavLink>
        <div className="mt-8 pb-0 text-white flex flex-col gap-6">
          <iframe
            src="https://www.youtube.com/embed/aQHe_8ZxPEw?si=-56H0rY73t6Q8VWH"
            title="Ibadan Student Tech Fest"
            className="bg-white text-black w-full h-[250px] sm:h-[350px] md:h-[450px] flex justify-center items-center rounded-[10px] mx-auto border-[1px ]"
          ></iframe>
        </div>
      </div>

      <div className="bg-secondary-color font-GilroyBoldItalic py-8 px-4 md:px-8 lg:px-16">
        <div className="max-w-[1290px] mx-auto">
          <h2 className="font-[700] text-[25px] mb-4 text-center text-white">
            Hightlight of the Event
          </h2>
          <div className="flex justify-center items-center flex-wrap gap-x-8 gap-y-16 py-8">
            <button className="py-2 px-4 border-2 border-primary-color hover:bg-primary-color bg-white w-[300px] text-primary-color hover:text-white">
              Hackathon
            </button>
            <button className="py-2 px-4 border-2 border-primary-color hover:bg-primary-color bg-white w-[300px] text-primary-color hover:text-white">
              School of the year
            </button>
            <button className="py-2 px-4 border-2 border-primary-color hover:bg-primary-color bg-white w-[300px] text-primary-color hover:text-white">
              Networking/Games
            </button>
            <button className="py-2 px-4 border-2 border-primary-color hover:bg-primary-color bg-white w-[300px] text-primary-color hover:text-white">
              Panel Session
            </button>
            <button className="py-2 px-4 border-2 border-primary-color hover:bg-primary-color bg-white w-[300px] text-primary-color hover:text-white">
              Break-out Session
            </button>
            <button className="py-2 px-4 border-2 border-primary-color hover:bg-primary-color bg-white w-[300px] text-primary-color hover:text-white">
              Products Exhibition
            </button>
          </div>
        </div>
      </div>
      <div
        className="py-8 px-4 md:px-8 lg:px-16 font-GilroyBoldItalic"
        id="ticket"
      >
        <h2 className="text-[25px] text-center mx-auto w-max">Tickets</h2>
        <div className="grid grid-cols-[repeat(1,minmax(200px,300px))] sm:grid-cols-[repeat(2,minmax(200px,350px))]  gap-8 justify-center">
          <div className="font-GilroyBold">
            <div className="text-center text-primary-color relative">
              <p className="uppercase text-[18px] tracking-wider">
                Premium Ticket
              </p>
              <p className="uppercase text-[14px] bg-gray-200 w-max mx-auto px-4 py-1 text-black tracking-[0.25em]">
                early bird
              </p>
              <p className="text-[30px] tracking-wider">6,000</p>
            </div>
            <ul className="bg-primary-color text-white shadow-sm rounded-[10px] flex flex-col p-4 px-10 list-disc font-GilroyLight font-bold min-h-[400px]">
              <div className="flex-grow flex flex-col gap-2">
                <li className="">Exclusive Ibstufest Swags</li>
                <li className="">Networking Opportunities</li>
                <li className="">Full Event Access</li>
                <li className="">Premium Refresment </li>
                <li className="">Certificate of Participation</li>
                <li className="">
                  Free bus transportation to the event venue on Day 1 and Day 2
                  from UI or Iwo Road.
                </li>
              </div>
              <div className="flex justify-center items-center">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://flutterwave.com/pay/gtxknjnspvus"
                  className="py-1 px-4 bg-white text-black rounded-[10px] hover:bg-gray-200"
                >
                  Get Ticket
                </a>
              </div>
            </ul>
          </div>
          <div className="font-GilroyBold">
            <div className="text-center text-primary-color relative">
              <p className="uppercase text-[18px] tracking-wider">
                Basic Ticket
              </p>
              <p className="uppercase text-[14px] bg-gray-200 w-max mx-auto px-4 py-1 text-black tracking-[0.25em]">
                EVERYONE
              </p>
              <p className="text-[30px] tracking-wider">FREE</p>
            </div>
            <ul className="bg-primary-color text-white shadow-sm rounded-[10px] flex flex-col p-4 px-10 list-disc font-GilroyLight font-bold min-h-[400px]">
              <div className="flex-grow flex flex-col gap-2">
                <li className="">Full Event access</li>
                <li className="">Networking Opportunities</li>
                <li className="">Regular Refreshment</li>
                <li className="">Free Transportation</li>
              </div>

              <div className="flex justify-center items-center">
                <a
                  target="_blank"
                  href="https://bit.ly/ibstufestfreeticket"
                  className="py-1 px-4 bg-white text-black rounded-[10px] hover:bg-gray-200"
                >
                  Get Ticket
                </a>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className="py-8" id="past-event">
        <div className="font-GilroyBoldItalic max-w-[1440px] mx-auto">
          <h2 className="font-[700] text-[25px] text-center">
            Our Past Events
          </h2>
          <div className="py-8 px-4 md:px-8 lg:px-16 flex-flex-col gap-8 grid grid-cols-[repeat(1,minmax(100px,360px))] sm:grid-cols-[repeat(2,minmax(100px,360px))] md:grid-cols-[repeat(3,minmax(100px,400px))] justify-center items-center">
            <img
              src="/images/event1.png"
              alt="previous event"
              className="h-[250px] w-full rounded-[10px] object-cover object-center grayscale hover:grayscale-0"
            />
            <img
              src="/images/event2.png"
              alt="previous event"
              className="h-[250px] w-full rounded-[10px] object-cover object-center grayscale hover:grayscale-0"
            />
            <img
              src="/images/event3.png"
              alt="previous event"
              className="h-[250px] w-full rounded-[10px] object-cover object-center grayscale hover:grayscale-0"
            />
            <img
              src="/images/event4.png"
              alt="previous event"
              className="h-[250px] w-full rounded-[10px] object-cover object-center grayscale hover:grayscale-0"
            />
            <img
              src="/images/event5.png"
              alt="previous event"
              className="h-[250px] w-full rounded-[10px] object-cover object-center grayscale hover:grayscale-0"
            />
            <img
              src="/images/event6.png"
              alt="previous event"
              className="h-[250px] w-full rounded-[10px] object-cover object-center grayscale hover:grayscale-0"
            />
          </div>
        </div>
      </div>

      <div className="bg-secondary-color font-GilroyBoldItalic py-8 text-white">
        <div className="max-w-[1440px] mx-auto px-4 md:px-8 lg:px-16">
          <div className="max-w-[800px] mx-auto">
            <h2 className="font-[700] text-[25px] mb-4 text-center">
              Spread the super power with us
            </h2>
            <p className="text-center">
              Aside from building and launching things, we take pride in
              bridging the gaps in the tech in Ibadan through hackathons,
              events, mentorship programs at IBstfest{" "}
            </p>
          </div>
          <div className="flex justify-center lg:justify-center items- flex-wrap gap-8 py-8">
            <div className="w-full max-w-[350px] border-[1px] rounded-[10px] p-2 flex flex-col gap-4 items-start">
              <img
                src="/images/vector1.png"
                alt="image1"
                className="bg-white px-6 py-2 rounded-[10px] block w-[100px]"
              />
              <div className="flex-grow">
                <h3 className="font-GilroyBold text-[20px]">Join Us</h3>
                <p className="font-GilroyLightItalic">
                  Collaborate with the next generation of tech talent young
                  makers, developers, and innovators.
                </p>
              </div>
              <a target="_blank" rel="noreferrer" href="http://atcafrica.com">
                <button className="bg-white text-black px-4 py-1 rounded-[10px]">
                  Join Us
                </button>
              </a>
            </div>
            <div className="w-full max-w-[350px] border-[1px] rounded-[10px] p-2  flex flex-col gap-4 items-start">
              <img
                src="/images/vector2.png"
                alt="image2"
                className="bg-white px-6 py-2 rounded-[10px] block w-[100px]"
              />
              <div className="flex-grow">
                <h3 className="font-GilroyBold text-[20px]">Partner With Us</h3>
                <p className="font-GilroyLightItalic">
                  Get involved in some of our initiatives either as a sponsor or
                  a partner.
                </p>
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://flutterwave.com/donate/yisv4f3rmjwq"
              >
                <button className="bg-white text-black px-4 py-1 rounded-[10px]">
                  Donate
                </button>
              </a>
            </div>
            <div className="w-full max-w-[350px] border-[1px] rounded-[10px] p-2  flex flex-col gap-4 items-start">
              <img
                src="/images/vector3.png"
                alt="image3"
                className="bg-white px-6 py-2 rounded-[10px] block w-[100px]"
              />
              <div className="flex-grow">
                <h3 className="font-GilroyBold text-[20px]">Support Us</h3>
                <p className="font-GilroyLightItalic">
                  Help us further, your contribution can contribute towards
                  every individual community
                </p>
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/2348123051357"
              >
                <button className="bg-white text-black px-4 py-1 rounded-[10px]">
                  Partner with Us
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-8">
        <div className="px-4 md:px-8 lg:px-16 text-[18px] font-GilroyBold">
          <h2 className="text-primary-color px-4 py-2 w-max rounded-[10px] m-auto font-[700] text-[25px]">
            Partners/Sponsors
          </h2>
          <Marquee
            className="flex gap-x-8 justify-center items-center my-4"
            speed={80}
            autoFill={true}
          >
            <img
              src="/images/paywave.svg"
              alt=""
              className="grayscale hover:grayscale-0 h-[50px]"
            />
            <img
              src="/images/te.png"
              alt=""
              className="grayscale hover:grayscale-0 h-[50px] mx-4"
            />
            <img
              src="/images/cmx-partners/technext.png"
              alt=""
              className="grayscale hover:grayscale-0 w-[170px] mx-4"
            />
            <img
              src="/images/cmx-partners/zikoro_logo.jpeg"
              alt=""
              className="grayscale hover:grayscale-0 w-[60px] mx-4"
            />
            <img
              src="/images/enforca.png"
              alt=""
              className="grayscale hover:grayscale-0 h-[50px] mx-4"
            />
            <img
              src="/images/bekkah.png"
              alt=""
              className="grayscale hover:grayscale-0 h-[50px] mx-4"
            />
            <img
              src="/images/tranarc.jpg"
              alt=""
              className="grayscale hover:grayscale-0 h-[50px] mx-4"
            />
          </Marquee>
        </div>
        <div className="px-4 md:px-8 lg:px-16 pt-0 text-[18px] font-GilroyBold">
          <h2 className="text-primary-color px-4 py-2 w-max rounded-[10px] m-auto font-[700] text-[25px]">
            Community Partners
          </h2>
          <Marquee
            className="flex gap-x-8 justify-center items-center"
            speed={80}
            autoFill={true}
          >
            <img
              src="/images/nacos.png"
              alt=""
              className="grayscale hover:grayscale-0 w-[150px]"
            />
            <img
              src="/images/gdgib.png"
              alt=""
              className="grayscale hover:grayscale-0 w-[200px] mx-4"
            />
            <img
              src="/images/cmx-partners/14g.jpeg"
              alt=""
              className="grayscale hover:grayscale-0 w-[100px] mx-4"
            />
            <img
              src="/images/cmx-partners/cmx.png"
              alt=""
              className="grayscale hover:grayscale-0 w-[150px] mx-4"
            />
            <img
              src="/images/cmx-partners/demohub.png"
              alt=""
              className="grayscale hover:grayscale-0 w-[150px] mx-4"
            />
            <img
              src="/images/cmx-partners/gdg-lautech.jpg"
              alt=""
              className="grayscale hover:grayscale-0 w-[200px] mx-4"
            />
            <img
              src="/images/cmx-partners/3mtt.jpg"
              alt=""
              className="grayscale hover:grayscale-0 w-[150px] mx-4"
            />
            <img
              src="/images/cmx-partners/outt.png"
              alt=""
              className="grayscale hover:grayscale-0 w-[150px] mx-4"
            />
            <img
              src="/images/cmx-partners/propel.jpg"
              alt=""
              className="grayscale hover:grayscale-0 w-[150px] mx-4"
            />
            <img
              src="/images/cmx-partners/safe-net.jpg"
              alt=""
              className="grayscale hover:grayscale-0 w-[80px] mx-4"
            />
            <img
              src="/images/cmx-partners/start-up.png"
              alt=""
              className="grayscale hover:grayscale-0 w-[150px] mx-4"
            />
            <img
              src="/images/cmx-partners/tableau.jpeg"
              alt=""
              className="grayscale hover:grayscale-0 w-[150px] mx-4"
            />
            <img
              src="/images/cmx-partners/tech-expo.png"
              alt=""
              className="grayscale hover:grayscale-0 w-[150px] mx-4"
            />
            <img
              src="/images/cmx-partners/tech-hub.png"
              alt=""
              className="grayscale hover:grayscale-0 w-[150px] mx-4"
            />
            <img
              src="/images/cmx-partners/rain.png"
              alt=""
              className="grayscale hover:grayscale-0 w-[150px] mx-4"
            />
            <img
              src="/images/cmx-partners/alx.jpeg"
              alt=""
              className="grayscale hover:grayscale-0 w-[80px] mx-4"
            />
          </Marquee>
        </div>
      </div>
      <div
        className="py-12 px-4 md:px-8 lg:px-16 w-full max-w-[900px] mx-auto"
        id="faqs"
      >
        <h2 className="font-GilroyBoldItalic text-[24px] mb-4 text-center">
          FAQs (Need help?)
        </h2>
        <div className="flex flex-col gap-1">
          <Accordion
            title="What is Ibadan Students Tech Fest?"
            text="The Ibadan Students Tech Fest is a gathering for students in Ibadan. It aims to demystify technology by providing insights into how it works and equipping individuals with the skills to navigate the tech landscape"
          />
          <Accordion
            title="When and where is it holding?"
            text="Ibadan Students Tech Fest will hold on 11th - 12th October 2024 at Dominion University along Ibadan/Lagos express-way"
          />
          <Accordion
            title="Can I register at the event?"
            text="It is advisable that you register and purchase your tickets before the day of the event for easy access to the Fest."
          />
          <Accordion
            title="Are there accommodation options for out-of-town attendees?"
            text="Yes, if the accommodation provided is not enough, the nearby hotel recommendations will be provided for out- of- town attendees which will attract some fee aside from registration."
          />
          <Accordion
            title="Are there discounts available for students and large groups?"
            text="Yes, there’s a discount available for community members. Check out the ticket section to see which category applies."
          />
          <Accordion
            title="What type of sessions and activities will be featured at Ibadan Students Tech Fest24"
            text="Expert Sessions, Hackathon, Capture the Flag Competition, Career Workshops, BreakOut session, Product Exhibition, and Side Attraction."
          />
          <Accordion
            title="Will transportation be made available for easy mobilization of attendees to the venue?"
            text="Yes, we are working on that as transportation will be made available in different locations which include Challenge, Iwo Road, UI and Apata Ibadan. In case you are coming from Ibadan, we will reach out to our campus leads."
          />
          <Accordion
            title="For inquiries about the event, is there a contact person?"
            text="Yes, kindly reach out to us through email hello@atcafrica.com or contact 08123051357"
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default Landing;
