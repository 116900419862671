import React from "react";
import PageLayout from "../components/layout/pageLayout";

const Match = () => {
  return (
    <PageLayout>
      <div className="h-[350px] md:h-[500px] bg-match-hero bg-no-repeat bg-cover bg-center px-4 md:px-8 lg:px-16 py-8">
        <div className="relative max-w-[1440px] mx-auto h-full flex justify-center">
          <div className="absolute h-full  md:right-0 max-w-[450px] items-center flex justify-center">
            <h1 className="text-primary-color text-center text-[35px] sm:text-[45px] md:text-left md:text-[50px] font-GilroyBold textShadow">
              Ibadan Students Tech fest Inter Football March 2024
            </h1>
          </div>
          ;
        </div>
      </div>
      <div className="max-w-[900px] font-GilroyBoldItalic mx-auto px-4 md:px-8 lg:px-16 py-16">
        <h2 className="font-GilroyExtraBoldItalic text-primary-color text-[20px] md:text-[25px] text-center text">
          What you should know about the Student Fest March
        </h2>
        <ul className="md:text-[18px] flex flex-col gap-y-4 mt-4">
          <li>
            - Registration is exclusive to teams representing institutions.
            Individual participants are not eligible
          </li>
          <li>
            - Eligible teams must arrive at the event no later than Friday to
            participate in training and match practice. Failure to do so may
            result in disqualification.
          </li>
        </ul>
        <div className="mt-4 flex justify-center items-center">
          <a
            target="_blank"
            href="https://bit.ly/ibstufestisc"
            className="text-white bg-primary-color px-4 py-1 rounded-[5px]"
          >
            Apply here
          </a>
        </div>
      </div>
    </PageLayout>
  );
};

export default Match;
