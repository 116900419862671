import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "../pages/landing";
import Institutions from "../pages/institution";
import Speakers from "../pages/speaker";
import Directions from "../pages/directions";
import About from "../pages/about";
import CtaProvider from "../context/ctaContext";
import Match from "../pages/match";
import CTF from "../pages/ctf";

const AppRouter = () => {
  return (
    <CtaProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/institutions" element={<Institutions />} />
          <Route path="/directions" element={<Directions />} />
          <Route path="/speakers" element={<Speakers />} />
          <Route path="/about" element={<About />} />
          <Route path="/match" element={<Match />} />
          <Route path="/ctf" element={<CTF />} />
        </Routes>
      </Router>
    </CtaProvider>
  );
};

export default AppRouter;
