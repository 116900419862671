import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

const Accordion = ({ title, text }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="border-2 bg-white">
      <div
        className={`${
          open && "border-b-2"
        } text-primary-color px-4 py-2 flex justify-between items-start text-[18px] md:text-[20px] font-GilroyBold gap-x-4`}
      >
        <h3 className="">{title}</h3>
        <button onClick={() => setOpen(!open)}>{open ? "-" : "+"}</button>
      </div>
      <AnimatePresence>
        {open && (
          <motion.p
            className="px-4 py-2 md:text-4 font-GilroyLight font-bold overflow-hidden"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "max-content" }}
            exit={{ opacity: 0, height: 0 }}
          >
            {text}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
