import React from "react";
import { Link } from "react-router-dom";

const Speaker = ({ speakerImage, speakerName, speakerPosition }) => {
  return (
    <div className="flex flex-col text-primary-color">
      <img
        src={speakerImage}
        alt="speaker"
        className={`grayscale hover:grayscale-0 border-8 border-primary-color w-full rounded-[10px] object-cover object-top h-[300px]`}
      />

      <div className="flex items-start justify-between">
        <div className="rounded-b-[10px] w-full py-1">
          <p className="font-[700] text-[16px] md:text-[18px]">{speakerName}</p>
          <p className="text-[14px]">{speakerPosition}</p>
        </div>
        {/* <div>
          <a href="#">
            <img
              src="/images/linkedIn.png"
              alt=""
              className="w-[40px] invert"
            />
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default Speaker;
