import React from "react";
import PageLayout from "../components/layout/pageLayout";
import Cta from "../components/cta";

const About = () => {
  return (
    <PageLayout>
      <div className="py-8 px-4 md:px-8 lg:px-16 font-GilroyBoldItalic w-full max-w-[1200px] mx-auto">
        <h2 className="text-primary-color text-[24px] mb-4 text-center">
          About the Event - IBstfest 24
        </h2>
        <p className="w-full text-[20px] max-w-[900px] mx-auto text-primary-color text-center">
          "The Ibadan Students Tech Fest is a gathering for students in Ibadan.
          It aims to demystify technology by providing insights into how it
          works and equipping individuals with the skills to navigate the tech
          landscape."
        </p>
        <div className="mt-8 pb-0 text-white flex flex-col gap-6">
          <iframe
            src="https://www.youtube.com/embed/aQHe_8ZxPEw?si=-56H0rY73t6Q8VWH"
            title="Ibadan Student Tech Fest"
            className="bg-white text-black w-full h-[250px] sm:h-[350px] md:h-[450px] flex justify-center items-center rounded-[10px] mx-auto border-[1px ]"
          ></iframe>
        </div>
        <div className="text-primary-color flex flex-col gap-2 mt-4 md:text-[18px]">
          <p>
            We are thrilled to unveil the theme for the Ibadan Students Tech
            Fest: "Hacking the Ecosystem: Disruptive for Technology Growth.
          </p>
          <p>
            This theme encapsulates our commitment to fostering a vibrant and
            dynamic ecosystem that nurtures innovation and empowers young
            talent.
          </p>
          <p>
            To cultivate a thriving technology ecosystem, we must invest in our
            youth. By providing opportunities for upskilling, mentorship, and
            collaboration, we can equip young talent with the skills and
            resources they need to become the driving force behind technological
            advancements in various tech field.
          </p>
          <p>
            As young talent grows and matures, they will fuel the innovation
            that propels the ecosystem forward. By supporting their development
            and promoting their ideas, we can create a self-sustaining cycle of
            growth and creativity.
          </p>
          <p>
            Nigeria's position as Africa's largest economy and most populous
            country presents immense potential for technological progress.
            However, we face the critical challenge of retaining talent within
            our ecosystem. To address this, we must create an environment that
            offers competitive opportunities, fosters a culture of innovation,
            and provides the necessary support for talent development.
          </p>
          <p>
            By investing in our youth, supporting innovation, and addressing the
            talent retention challenge, we can create a brighter future for
            Nigeria and position our country as a global leader in technology
            and innovation.
          </p>
        </div>
      </div>
    </PageLayout>
  );
};

export default About;
