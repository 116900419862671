import React, { createContext, useContext, useEffect, useState } from "react";

const CtaContext = createContext(null);

const CtaProvider = ({ children }) => {
  const [status, setStatus] = useState(false);
  useEffect(() => {
    setStatus(true);
  }, []);
  const handleStatus = (stat) => {
    setStatus(stat);
  };
  return (
    <CtaContext.Provider value={{ status, handleStatus }}>
      {children}
    </CtaContext.Provider>
  );
};

export default CtaProvider;

const useCtaContext = () => {
  return useContext(CtaContext);
};

export { useCtaContext };
