import React from "react";
import Speaker from "../components/speaker";
import PageLayout from "../components/layout/pageLayout";

const Speakers = () => {
  return (
    <PageLayout>
      <div
        className="py-8 px-4 md:px-8 lg:px-16 font-GilroyBoldItalic"
        id="speakers"
      >
        <h2 className="text-primary-color font-[700] text-[25px] mb-8 text-center">
          Speakers
        </h2>
        <div
          className="max-w-[1440px] justify-center mx-auto flex-flex-col gap-6 grid
            grid-cols-[repeat(1,minmax(100px,350px))]
            sm:grid-cols-[repeat(2,minmax(100px,350px))]
            lg:grid-cols-[repeat(3,minmax(100px,350px))]
            items-center"
        >
          <Speaker
            speakerImage="/images/speakers/SEUN-FAKOREDE.jpg"
            speakerName="Hon. Seun Fakorede"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Engr. Adabanija Raheemat Abisola.jpeg"
            speakerName="Engr. Adabanija Raheemat"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Adebayo Akande.jpeg"
            speakerName="Adebayo Akande"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Abel Olorunnisola  VC, Dominion University.jpg"
            speakerName="Abel Olorunnisola"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Danjuma Balogun.jpeg"
            speakerName="Danjuma Balogun"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/David Adeleke.jpeg"
            speakerName="David Adeleke"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Tope James Moses.jpeg"
            speakerName="Tope James Moses"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Ayomide Fajobi  CEO Tranarc.jpg"
            speakerName="Ayomide Fajobi"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Jesudamilare Adesegun-David.png"
            speakerName="Jesudamilare Adesegun-David"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Josiah Adegboye.png"
            speakerName="Josiah Adegboye"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Ayobami Atolagbe.jpeg"
            speakerName="Ayobami Atolagbe"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Hamzat Oluwaseun.jpeg"
            speakerName="Hamzat Oluwaseun"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Segun Olaniyan.jpeg"
            speakerName="Segun Olaniyan"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Mosopefoluwa Ayodele.jpg"
            speakerName="Mosopefoluwa Ayodele"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Ibiniyi Obikoya  Senior Software Engineer at Kora.jpg"
            speakerName="Ibiniyi Obikoya"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Tim Oladoyinbo.png"
            speakerName="Tim Oladoyinbo"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Dr. Aituaz Kola-Oladejo,.jpeg"
            speakerName="Dr. Aituaz Kola-Oladejo"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Olabode miracle.jpeg"
            speakerName="Olabode miracle"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Amusan Oluwatumininu.png"
            speakerName="Amusan Oluwatumininu"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Abidemi Tiamiyu.webp"
            speakerName="Abidemi Tiamiyu"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Kausar Moshood.jpeg"
            speakerName="Kausar Moshood"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Adebayo Akande.jpeg"
            speakerName="Adebayo Akande"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Emmanuel Aiyenigba.png"
            speakerName="Emmanuel Aiyenigba"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Dr. Olusola Sayeed Ayoola.jpg"
            speakerName="Dr. Olusola Sayeed Ayoola"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Joshua_Olajide.png"
            speakerName="Joshua Olajide"
            speakerPosition=""
          />
          <Speaker
            speakerImage="/images/speakers/Oladotun Ajayi.png"
            speakerName="Oladotun Ajayi"
            speakerPosition=""
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default Speakers;
