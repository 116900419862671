import React from "react";
import PageLayout from "../components/layout/pageLayout";

const Directions = () => {
  return (
    <PageLayout>
      <div
        className="py-8 px-4 md:px-8 lg:px-16 font-GilroyBoldItalic"
        id="speakers"
      >
        <h2 className="text-primary-color font-[700] text-[25px] mb-8 text-center">
          Directions
        </h2>
       <div>
        {/* AIzaSyAka2VAQKtF8HduTndsxW8Id1mY9BIk0C4 */}
       </div>
      </div>
    </PageLayout>
  );
};

export default Directions;
