import React from "react";
import PageLayout from "../components/layout/pageLayout";

const CTF = () => {
  return (
    <PageLayout>
      <div className="h-[350px] md:h-[500px] bg-gray-600 bg-ctf-hero bg-blend-multiply bg-no-repeat bg-cover bg-center px-4 md:px-8 lg:px-16 py-8">
        <div className="relative max-w-[1440px] mx-auto h-full flex justify-center">
          <div className="absolute h-full  md:right-0 max-w-[450px] items-center flex justify-center">
            <h1 className="text-primary-color text-center text-[35px] sm:text-[45px] md:text-left md:text-[50px] font-GilroyBold textShadow">
              Ibadan Students Tech fest Capture the Flag
            </h1>
          </div>
          ;
        </div>
      </div>
      <div className="max-w-[900px] font-GilroyBoldItalic mx-auto px-4 md:px-8 lg:px-16 py-16">
        <h2 className=" font-GilroyExtraBoldItalic  text-primary-color text-[20px] md:text-[25px] text-center">
          It is time to Capture the Flag
        </h2>
        <div className="md:text-[18px] mt-4 flex flex-col gap-y-4">
          <p>
            Dive into our Capture the Flag (CTF) competition, where you'll face
            off in a series of security-based challenges and puzzles. This event
            is designed to sharpen your problem-solving abilities and
            cybersecurity skills as you uncover hidden flags and demonstrate
            your expertise in a competitive environment.
          </p>
          <p>
            <span className="text-primary-color">Objective: </span>This
            competition challenges participants to solve various cybersecurity
            problems in a controlled environment, testing their skills in areas
            like cryptography, File Hashing,Binary Exploitation, Steganography,
            Network security, and ethical hacking.
          </p>
          <div>
            <p>Format: Hybrid (Virtually & Physically)</p>
            <p>Equipment: Bring Your Own Devices (BYOD)</p>
          </div>
        </div>
        <div className="mt-4 flex justify-center items-center">
          <a
            href="http://"
            className="text-white bg-primary-color px-4 py-1 rounded-[5px]"
          >
            Apply here
          </a>
        </div>
      </div>
    </PageLayout>
  );
};

export default CTF;
