import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSponsor, setIsSponsor] = useState(false);
  const text =
    "I am interesting in partnering/sponsoring the Ibadan student tech fest.";
  return (
    <header className="bg-primary-color text-white header sticky top-0 z-50 font-GilroyLight">
      <div className="relative px-4 md:px-8 lg:px-16 py-8 rounded-[11px] max-w-[1440px] mx-auto flex justify-between items-center">
        <Link href="https://ibstfest24.com">
          <img
            src="/images/logo.svg"
            alt="logo"
            className="w-[130px] sm:w-[130px] lg:w-full  h-[50px] -mt-[10px] md:-mt-[15px]"
          />
        </Link>
        <button
          className="lg:hidden w-[30px]"
          onClick={() => {
            setIsSponsor(false);
            setIsNavOpen(!isNavOpen);
          }}
        >
          <img
            src="/icons/three-horizontal-lines-outline-icon.svg"
            alt=""
            className="invert"
          />
        </button>

        {/* Mobile Navigation */}
        {isNavOpen && (
          <div className="bg-primary-color border-2 border-primary-color absolute top-[90px] left-0 right-0 w-full lg:hidden flex flex-col justify-between items-center gap-y-[1.5rem] py-4 h-[300px]">
            <nav className="text-[1rem] flex flex-col items-center justify-between gap-y-[1.5rem]">
              <NavLink to="/">Home</NavLink>
              <p
                onClick={() => {
                  setIsNavOpen(false);
                  setIsSponsor(true);
                }}
                className="flex gap-2 items-center"
              >
                About IBstufest
                <img
                  src="/icons/arrow-up.svg"
                  alt=""
                  className="about-arrow invert"
                />
              </p>
              <NavLink to="/institutions">Institutions</NavLink>
              <NavLink to="/speakers">Speakers</NavLink>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://wa.me/2348123051357?text=${text}`}
                className="flex gap-[1px]"
              >
                Become a Partner/Sponsor
              </a>
            </nav>
          </div>
        )}

        {isSponsor && (
          <div className="bg-primary-color border-2 border-primary-color absolute top-[90px] left-0 right-0 w-full lg:hidden flex flex-col justify-between items-center gap-y-[1.5rem] py-4 px-8 md:px-8 lg:px-16 ">
            <button
              className="self-start block"
              onClick={() => {
                setIsSponsor(false);
                setIsNavOpen(true);
              }}
            >
              <img
                src="icons/arrow-thin-chevron-left-icon.svg"
                alt=""
                className="w-[8px] invert"
              />
            </button>
            <NavLink to="/about" className="">
              About IBstufest
            </NavLink>
            <NavLink to="/match">Inter Football Match</NavLink>
            <NavLink to="/ctf">Apply for CTF</NavLink>
            <a target="_blank" rel="noreferrer" href="https://bit.ly/ibstfest">
              Volunteer
            </a>
            <a href="/#faqs" className="">
              FAQs
            </a>
          </div>
        )}

        {/* Desktop Navigation */}
        <div className="hidden lg:flex justify-between items-center gap-x-[1rem]">
          <nav className="text-[1rem] flex items-center justify-between gap-x-[.5rem] lg:gap-x-[2.5rem]">
            <NavLink to="/">Home</NavLink>
            <div className="text-center about-wrapper cursor-pointer relative">
              <span className="flex gap-2">
                About IBstufest
                <img
                  src="/icons/arrow-up.svg"
                  alt=""
                  className="about-arrow invert"
                />
              </span>

              <span className="drop-down absolute top-2 w-max -left-[5px]">
                <ul className="flex flex-col gap-2 mt-8 shadow-[0_0_5px_-0px_rgba(0,0,0,0.3)] rounded-[8px] w-[200px] px-4 py-2 z-[5555] bg-white">
                  <NavLink
                    to="/about"
                    className="text-black hover:text-primary-color hover:bg-[rgba(218,218,218,0.5)] py-1 px-2 rounded-[4px]"
                  >
                    About IBstufest
                  </NavLink>
                  <li className="bg-[#DADADA] h-[1px]"></li>
                  <NavLink
                    to="/match"
                    className="text-black hover:text-primary-color hover:bg-[rgba(218,218,218,0.5)] py-1 px-2 rounded-[4px]"
                  >
                    Inter Football Match
                  </NavLink>
                  <li className="bg-[#DADADA] h-[1px]"></li>
                  <NavLink
                    to="/ctf"
                    className="text-black hover:text-primary-color hover:bg-[rgba(218,218,218,0.5)] py-1 px-2 rounded-[4px]"
                  >
                    Apply for CTF
                  </NavLink>
                  <li className="bg-[#DADADA] h-[1px]"></li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://bit.ly/ibstfest"
                    className="text-black hover:text-primary-color hover:bg-[rgba(218,218,218,0.5)] py-1 px-2 rounded-[4px]"
                  >
                    Volunteer
                  </a>
                  <li className="bg-[#DADADA] h-[1px]"></li>
                  <a
                    href="/#faqs"
                    className="text-black hover:text-primary-color hover:bg-[rgba(218,218,218,0.5)] py-1 px-2 rounded-[4px]"
                  >
                    FAQs
                  </a>
                </ul>
              </span>
            </div>
            <NavLink to="/institutions">Institutions</NavLink>
            <NavLink to="/speakers">Speakers</NavLink>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://wa.me/2348123051357?text=${text}`}
              className="flex gap-[1px]"
            >
              Become a Partner/Sponsor
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
