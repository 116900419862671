import React from "react";
import PageLayout from "../components/layout/pageLayout";

const Institutions = () => {
  return (
    <PageLayout>
      <div className="" id="">
        <div className="font-GilroyBoldItalic max-w-[1440px] mx-auto px-4 md:px-8 lg:px-16 py-8">
          <h2 className="font-[700] text-primary-color text-[25px] text-center">
            Institutions
          </h2>
          <ul className="text-primary-color text-4 lg:text-[18px] flex flex-col gap-4 mt-4">
            <li>- University of Ibadan</li>
            <li>- Precious Cornerstone University Ibadan</li>
            <li>- Ajayi Crowther University, Oyo Town</li>
            <li>- Koladisi University Ibadan</li>
            <li>- Redeemer University - Iwo</li>
            <li>- McPherson University, Lagos-Ibadan Express way </li>
            <li>- City Polytechnic Ibadan</li>
            <li>- The Polytechnic Ibadan </li>
            <li>- Dominion University</li>
            <li>- Bolmor Polytechnic</li>
            <li>- Dominican University</li>
            <li>- Lead City University</li>
            <li>- LAUTECH, Ogbomoso </li>
            <li>- The Oke Ogun Polytechnic, Saki</li>
            <li>- Federal Cooperative College, Ibadan</li>
            <li>- Federal School of Statistics, Ibadan</li>
            <li>- Sapade Polythecnic, Ogun State</li>
            <li>- Eruwa Polytechnic</li>
            <li>- First Technical University</li>
            <li>- Atiba University</li>
            <li>- Alayande College of Education, Oyo</li>
          </ul>
        </div>
      </div>
    </PageLayout>
  );
};

export default Institutions;
